import { request } from "@/utils/index";

const login = async (username, password) => {

    var res = request.post('/user/login', {'email': username, 'password': password}).then(function (response) {
        return response;
    });

    return res;
};

const incorrectAnswer = async (id) => {

    var res = request.get('/quiz/ircorrectAnswer/' + id).then(function (response) {
        return response;
    });

    return res;
};

const getActive = async () => {
    var res = request.get('/task/getActive').then(function (response) {
        return response;
    });

    return res;
}

const getQuiz = async (id) => {
    var res = request.get('/quiz/start/' + id).then(function (response) {
        return response;
    });

    return res;
}

const saveQuiz = async (id, data) => {
    var res = request.post('/quiz/finish/' + id, data).then(function (response) {
        return response;
    });

    return res;
}

const saveCrossword = async (id, data) => {
    var res = request.post('/crossword/finish/' + id, data).then(function (response) {
        return response;
    });

    return res;
}

const register = async (data) => {
    var res = request.post('/user/register', data).then(function (response) {
        return response;
    });

    return res;
}

const getCrossword = async (id) => {
    var res = request.get('/crossword/start/' + id).then(function (response) {
        return response;
    });

    return res;
}

export const model = {
  login,
  getActive,
  getQuiz,
  getCrossword,
  incorrectAnswer,
  saveQuiz,
  register,
  saveCrossword
};

import config from "../config";
import axios from "axios";
const headers = {};
headers['Accept'] = "application/json";

var token = localStorage.getItem('token');

if (token)
{
    headers['authorization'] = "Bearer " + token;
}

export const request = axios.create({
    baseURL: config.API_URL,
    headers: headers,
    responseType: 'json',
    validateStatus: function (status) {
        if (status == 401)
        {
            localStorage.removeItem('token');
            location.reload(true);
        }
        return true;
    },
});
